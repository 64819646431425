import { useInterval }                 from "@mantine/hooks";
import { RESET }                       from "jotai/utils";
import { useRouter }                   from "next/router";
import { useEffect, useRef, useState } from "react";

import { useGetLoggedIn }   from "_common/hooks/auth";
import { useSetLoginPopup } from "_common/hooks/global";
import { isPageRoute }      from "_common/utils";
import { useGetIsPlaying }  from "_components/features/player/states";
import {
	useGetCanWatchMoreContent,
	useGetRemainingTime,
	useSetCanWatchMoreContent,
	useSetRemainingTime
} from "_jotai/compulsoryLogin";

const FreeContent = () => {

	const isLoggedIn                                    = useGetLoggedIn ();
	const isPlaying                                     = useGetIsPlaying ();
	const setLoginPopup                                 = useSetLoginPopup ();
	const router                                        = useRouter ();
	const remainingTime                                 = useGetRemainingTime ();
	const [ localRemainingTime, setLocalRemainingTime ] = useState ( remainingTime?.value );
	const setRemainingTime                              = useSetRemainingTime ();
	const setCanWatchMoreContent                        = useSetCanWatchMoreContent ();
	const canWatchMoreContent                           = useGetCanWatchMoreContent ();
	const localRemainingTimeRef                         = useRef ( localRemainingTime );

	const setWithExpiry = value => {
		const expirationInMinutes = 24 * 60;
		const now                 = new Date ();
		const item                = {
			value  : value,
			expiry : now.getTime () + expirationInMinutes * 60 * 1000 // expiration time in milliseconds
		};

		setRemainingTime ( item );
	};

	function getWithExpiry () {
		const item = remainingTime;

		if ( !item ) {
			return null; // Item does not exist
		}

		const now = new Date ();

		if ( now.getTime () > item.expiry ) {
			setRemainingTime ( RESET );

			return null; // Item has expired
		}

		return item.value; // Return the value if not expired
	}

	// this interval is 1000ms
	// and we are deducting 1s at every 1000ms
	const interval = useInterval ( () => {
		setLocalRemainingTime ( s => {
			if ( s > 0 ) {
				return s - 1000;
			}
			// dont allow to watch more content if the remaining time is less than 0
			setCanWatchMoreContent ( false );
			setLoginPopup ( true );
			// setInLocalStorageInterval.stop ();
			interval.stop ();

			return s;
		} );
	}, 1000 );

	const setInLocalStorageInterval = useInterval ( () => {
		if ( localRemainingTimeRef.current > 0 ) {
			setWithExpiry ( localRemainingTimeRef.current );
		}
	}, [ 3000 ] );

	useEffect ( () => {
		if ( isLoggedIn ) {
			interval.stop ();
			setCanWatchMoreContent ( true );
			setInLocalStorageInterval.stop ();

			return;
		}

		const item = remainingTime;
		const now  = new Date ();

		if ( now.getTime () < item?.expiry )
			handlePaymentWallFlow ();

	}, [ isLoggedIn ] );

	useEffect ( () => {
		localRemainingTimeRef.current = localRemainingTime;
	}, [ localRemainingTime ] );

	const onUnmount = () => {
		interval.stop ();
		setInLocalStorageInterval.stop ();
	};

	const checkPageRoute = page => isPageRoute ( { router, page } );

	useEffect ( () => {
		const isStreamPage = checkPageRoute ( "stream" );
		const isVideoPage  = checkPageRoute ( "video" );
		const isReelsPage  = checkPageRoute ( "reel" );

		if ( !canWatchMoreContent ) {
			if ( isStreamPage || isVideoPage || isReelsPage )
				setLoginPopup ( true );
		}
	}, [ router.asPath ] );

	useEffect ( () => {
		handlePaymentWallFlow ();

		return onUnmount;
	}, [ isPlaying ] );

	function handlePaymentWallFlow () {
		if ( isLoggedIn )
			return;

		const _remainingTime = getWithExpiry ();

		if ( !interval.active ) {
			if ( _remainingTime ) {
				setLocalRemainingTime ( _remainingTime );
			} else {
				setCanWatchMoreContent ( true );
				setLocalRemainingTime ( 5 * 60 * 1000 );
			}
			setInLocalStorageInterval.start ();
			interval.start ();
		}

		if ( !isPlaying ) {
			onUnmount ();
		}

	}

	return null;
};

export default FreeContent;
